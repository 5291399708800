import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const defaultTheme = createTheme();
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: orange[400],
            dark: orange[500],
            light: orange[300],
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(0,0,0, 0.3)`,
          },
          success: {
            main: "#00c853",
            dark: "#00c853",
            light: "#00c853",
          },
          text: {
            primary: "#fff",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#0c1117",
            paper: "#0c1117",
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: orange[600],
              dark: orange[700],
              light: orange[500],
            },
            name: "custom",
          }),
        }
      : {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: orange[700],
            dark: orange[800],
            light: orange[600],
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `#f4f4f4`,
          },
          text: {
            primary: "#0c0627",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#f8f8f8",
            paper: "#ffffff",
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: orange[600],
              dark: orange[700],
              light: orange[500],
            },
            name: "custom",
          }),
        }),
  },
});

export default getDesignTokens;
