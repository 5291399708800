import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import getDesignTokens from "../../../constants/theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const MainBackdrop = () => {
  const [mode, setMode] = useState("light");
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "block",
            backgroundColor: "#01090e",
          }}
          open={true}>
          <Box
            sx={{ p: { md: 2, sm: 0 }, height: `100%`, position: "relative" }}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  ml: 1,
                  cursor: "pointer",
                  mt: 2,
                }}></Box>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}>
              <CircularProgress
                sx={{
                  color: "#ff7d3b",
                  position: "absolute",
                  top: `30%`,
                  left: { md: `38%`, sm: `10%` },
                }}
                size={250}
                thickness={9}
              />
            </Box>
          </Box>
        </Backdrop>
      </ThemeProvider>
    </>
  );
};
MainBackdrop.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  appLayer: PropTypes.bool,
};

export default MainBackdrop;
