import React, { Suspense, lazy } from "react";
import MainBackdrop from "../components/layouts/spinners/Backdrop";
const Login = lazy(() => import("../components/views/auth/Login"));
const NotFound = lazy(() => import("../components/views/404/NotFound"));
const Games = lazy(() => import("../components/views/private/games/Games"));

const PublicLayout = lazy(() => import("../components/layouts/PublicLayout"));
const PrivateLayout = lazy(() => import("../components/layouts/PrivateLayout"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const PublicRoute = lazy(() => import("./PublicRoute"));

const routes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<MainBackdrop />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/login",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      /***
      *  {
        path: "/loading-animation",
        element: (
          <PublicRoute>
            <MainBackdrop />
          </PublicRoute>
        ),
      },
      */
    ],
  },

  {
    path: "*",
    element: (
      <PublicRoute>
        <NotFound />
      </PublicRoute>
    ),
  },
  {
    path: "/accounts",
    element: (
      <Suspense fallback={<MainBackdrop />}>
        <PrivateLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Games />
          </PrivateRoute>
        ),
      },
      {
        path: "/accounts/games",
        element: (
          <PrivateRoute>
            <Games />
          </PrivateRoute>
        ),
      },
    ],
  },
];
export default routes;
